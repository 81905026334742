var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.pageFlag }, [
    _c(
      "div",
      { staticClass: "upload-btn-box" },
      [
        _c("img", {
          attrs: {
            alt: "Vue logo",
            src: require("@/assets/img/enterprise/upload.png")
          }
        }),
        _vm.capture
          ? [
              _c("input", {
                ref: "fileInput",
                staticClass: "upload-input",
                attrs: {
                  type: "file",
                  capture: _vm.capture,
                  accept: _vm.acceptType
                },
                on: {
                  change: function($event) {
                    return _vm.changeImage($event)
                  }
                }
              }),
              _vm._t("default")
            ]
          : [
              _c("input", {
                ref: "fileInput",
                staticClass: "upload-input",
                attrs: {
                  type: "file",
                  multiple: _vm.multiple,
                  accept: _vm.acceptType
                },
                on: {
                  change: function($event) {
                    return _vm.changeImage($event)
                  }
                }
              }),
              _vm._t("default")
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }