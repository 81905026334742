/**
 * 获取base64编码生成的图片的文件流大小
 * base64: base64字符串
 * return 图片的文件流大小，单位：字节
 */
export default function getBase64Size (base64) {
  base64 = base64.split(',')[1] // base64正文编码
  const equalIndex = base64.indexOf('=') // 获取=下标
  if (equalIndex > 0) base64 = base64.substring(0, equalIndex)
  const strLength = base64.length // 原来的字符流大小
  return parseInt(strLength - (strLength / 8) * 2, 0)
}
