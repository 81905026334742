<template>
  <div :class="pageFlag">
    <!-- 图片选择框 -->
    <div class="upload-btn-box">
      <img alt="Vue logo" src="~@/assets/img/enterprise/upload.png" />
      <template v-if="capture">
        <input
          type="file"
          class="upload-input"
          ref="fileInput"
          :capture="capture"
          @change="changeImage($event)"
          :accept="acceptType"
        />
        <!-- 用于显示自定义内容的插槽 -->
        <slot></slot>
      </template>
      <template v-else>
        <input
          type="file"
          class="upload-input"
          ref="fileInput"
          :multiple="multiple"
          @change="changeImage($event)"
          :accept="acceptType"
        />
        <!-- 用于显示自定义内容的插槽 -->
        <slot></slot>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast, Indicator } from 'mint-ui' // 引入提示弹窗组件
import getBase64Size from '@/assets/js/getBase64Size' // 获取base64编码生成的图片的文件流大小函数
/* eslint no-bitwise: ["error", { "allow": ["~"] }] */
import Exif from 'exif-js' // 引入图片压缩模块

export default {
  data() {
    return {
      file: {}, // 上传图片文件
      cover: '' // 图片路径或base64
    }
  },
  props: {
    pageFlag: {
      // 加载样式参数，用于判断页面显示时加载哪种样式
      type: String,
      required: false,
      default: 'upload-img'
    },
    addIconShow: {
      // 是否显示添加图标参数
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * 接受类型
     * 捕获到系统默认的设备capture: camera：相机，camcorder：摄像，microphone：录音
     */
    acceptType: {
      type: String,
      required: false,
      default: 'image/*'
    },
    capture: {
      type: String,
      required: false,
      default: ''
    },
    multiple: {
      type: String,
      required: false,
      default: ''
    },
    // 单个图片大小上限，单位：M
    maxImgLength: {
      type: Number,
      require: false,
      default: 10
    },
    // 单个图片压缩后的比例（小数）
    compressRatio: {
      type: Number,
      require: false,
      default: 0.36
    },
    typeVal: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['id'])
  },
  created() {},
  methods: {
    // 点击文本框获取图片文件
    changeImage(e) {
      console.log(e.target.files.length)
      if (e.target.files.length > 1) {
        const instance = Toast({
          message: '只能选择一张图片上传',
          position: 'middle',
          duration: 5000
        })
        setTimeout(() => {
          instance.close()
        }, 3500)
      } else {
        this.file = e.target.files[0] // 读取文件内容
        if (this.file) {
          // 判断是否加载文件
          const fileType = this.file.type // 获取文件类型
          const reg = new RegExp(/jpg|jpeg|png|gif/) // 验证图片类型正则表达式
          const that = this
          let Orientation
          // 去获取拍照时的信息，解决拍出来的照片旋转问题
          Exif.getData(this.file, function () {
            Orientation = Exif.getTag(this, 'Orientation')
          })
          if (reg.test(fileType.substr(fileType.lastIndexOf('/') + 1))) {
            // 验证文件是否是图片类型
            Indicator.open('请稍候...')
            const reader = new FileReader() // 创建图片转码器
            reader.readAsDataURL(this.file) // 将图片转base64编码
            reader.onload = function () {
              const result = this.result
              const img = new Image()
              img.src = result
              const resLenght = getBase64Size(result)
              // 判断图片是否大于上限，若大于阻止上传并给出提示信息
              Indicator.close()
              // if (resLenght <= 307200) {
              //   that.cover = result
              //   that.$emit('sendOrgImg', result) // 发送原图片给父组件
              //   that.$refs.fileInput.value = '' // 清空缓存，解决不能上传同一图片的问题
              // } else if (resLenght > 307200 && resLenght <= (that.maxImgLength * 1048576)) {
              // img.onload = function () {
              //   that.$emit('sendOrgImg', that.compress(img, Orientation))
              //   that.cover = that.compress(img, Orientation)
              // }
              // } else {
              //   const instance = Toast({
              //     message: '图片过大，请上传' + that.maxImgLength + 'M以内的图片',
              //     position: 'middle',
              //     duration: 5000
              //   })
              //   setTimeout(() => {
              //     instance.close()
              //   }, 3500)
              // }
              if (resLenght <= that.maxImgLength * 1048576) {
                // if (that.typeVal === 'confer') {
                //   that.$emit('sendOrgImg', result)
                // } else {
                img.onload = function () {
                  that.$emit('sendOrgImg', that.compress(img, Orientation))
                }
                // }
              } else {
                const instance = Toast({
                  message: '图片过大，请上传' + that.maxImgLength + 'M以内的图片',
                  position: 'middle',
                  duration: 3000
                })
                setTimeout(() => {
                  instance.close()
                }, 3500)
              }
            }
          } else {
            // 不是图片给出提示
            const instance = Toast({
              message: '请选择图片上传',
              position: 'middle',
              duration: 5000
            })
            setTimeout(() => {
              instance.close()
            }, 3500)
          }
        }
      }
    },
    compress(img, Orientation) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 创建canvas
      if (img && img.width > 1000 && img.height > 1000) {
        img.width = img.width / 4.5
        img.height = img.height / 4.5
      }

      if (Orientation && Orientation === 6 && img.width > img.height) {
        canvas.width = img.height
        canvas.height = img.width
      } else {
        canvas.width = img.width
        canvas.height = img.height
      }
      ctx.drawImage(img, 0, 0, img.width, img.height)
      // // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      // let ratio = width * height / 4000000
      // if (ratio > 1) {
      //   ratio = Math.sqrt(ratio)
      //   width /= ratio
      //   height /= ratio
      // } else {
      //   ratio = 1
      // }
      // canvas.width = width
      // canvas.height = height
      // 铺底色
      // ctx.fillStyle = '#fff'
      // ctx.fillRect(0, 0, canvas.width, canvas.height)
      // // 如果图片像素大于100万则使用瓦片绘制
      // let count = width * height / 1000000
      // if (count > 1) {
      //   count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
      //   // 计算每块瓦片的宽和高
      //   const nw = ~~(width / count)
      //   const nh = ~~(height / count)
      //   tCanvas.width = nw
      //   tCanvas.height = nh
      //   for (let i = 0; i < count; i += 1) {
      //     for (let j = 0; j < count; j += 1) {
      //       tctx.drawImage(img, i * nw * ratio,
      //         j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
      //       ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
      //     }
      //   }
      // } else {
      //   ctx.drawImage(img, 0, 0, width, height)
      // }

      // 修复ios上传图片的时候 被旋转的问题
      // alert(Orientation)
      // alert(img.width)
      // alert(img.height)
      if (Orientation !== '' && Orientation !== 1) {
        switch (Orientation) {
          case 6: // 需要顺时针（向左）90度旋转
            ctx.rotate((90 * Math.PI) / 180)
            if (img.width > img.height) {
              ctx.drawImage(img, 0, 0, img.width, -img.height)
            } else {
              ctx.drawImage(img, 0, 0, -img.width, img.height)
            }
            break
          case 8: // 需要逆时针（向右）90度旋转
            ctx.rotate((270 * Math.PI) / 180)
            ctx.drawImage(img, -img.height, 0, img.height, img.width)
            break
          case 3: // 需要180度旋转
            ctx.rotate((180 * Math.PI) / 180)
            ctx.drawImage(img, -img.width, -img.height, img.width, img.height)
            break
          default:
            break
        }
      }
      // 进行最小压缩
      const ndata = canvas.toDataURL('image/jpeg', this.compressRatio)
      canvas.width = 0
      canvas.height = 0
      canvas.width = 0
      canvas.height = 0
      const time = new Date().getTime()
      const name = 'BXD' + this.id + '_' + time
      return this.convertBase64UrlToBlob(ndata, name)
    },
    // 旋转图片
    // rotateImg(img, direction, canvas) {
    //   // 最小与最大旋转方向，图片旋转4次后回到原方向
    //   const minStep = 0
    //   const maxStep = 3
    //   if (img == null) return
    //   // img的高度和宽度不能在img元素隐藏后获取，否则会出错
    //   const height = img.height
    //   const width = img.width
    //   let step = 2
    //   if (step == null) step = minStep
    //   if (direction === 'right') {
    //     step += 1
    //     // 旋转到原位置，即超过最大值
    //     if (step > maxStep) step = minStep
    //   } else {
    //     step -= 1
    //     if (step < minStep) step = maxStep
    //   }
    //   // 旋转角度以弧度值为参数
    //   const degree = step * 90 * Math.PI / 180
    //   const ctx = canvas.getContext('2d')
    //   switch (step) {
    //   case 0:
    //     canvas.width = width
    //     canvas.height = height
    //     ctx.drawImage(img, 0, 0)
    //     break
    //   case 1:
    //     canvas.width = height
    //     canvas.height = width
    //     ctx.rotate(degree)
    //     ctx.drawImage(img, 0, -height)
    //     break
    //   case 2:
    //     canvas.width = width
    //     canvas.height = height
    //     ctx.rotate(degree)
    //     ctx.drawImage(img, -width, -height)
    //     break
    //   case 3:
    //     canvas.width = height
    //     canvas.height = width
    //     ctx.rotate(degree)
    //     ctx.drawImage(img, -width, 0)
    //     break
    //   default:
    //     break
    //   }
    // },
    // 更新预览，用于父组件直接更新图片预览显示
    // 此处代码不够优雅，需要修改
    updataCover(data) {
      this.cover = data
    },
    // 转为BLOB
    convertBase64UrlToBlob(urlData, fileName) {
      const arr = urlData.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime })
    }
  }
}
</script>

<style lang="less" type="text/css" scoped>
.upload-img {
  /* 组件整体样式 */
  width: 100px;
  height: 100px;
  margin: 20px 17.5px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-img .upload-btn-box {
  position: relative;
}

.upload-img .upload-btn-box img {
  width: 100px;
  height: 100px;
}

.upload-img .upload-input {
  /* 上传框 */
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100px;
  height: 100px;
  opacity: 0;
  border: 1px solid black;
}

.upload-img .btn-img {
  /* 上传区域 */
  position: relative;
  border-radius: 4px;
  width: 99px;
  height: 69px;
  margin: 0 auto 12px auto;
  padding: 4px;
  text-align: center;
  background-color: #f8b83a;
}

.upload-img .btn-img.preview-img {
  /* 图片预览区域 */
  border: 1px solid #f8b83a;
  background-color: #fff;

  img {
    width: 100%;
  }
}

.upload-img .btn-img .iconfont {
  /* 上传图标 */
  position: absolute;
  top: 21px;
  left: 38px;
  font-size: 12px;
  color: #fff;
}

.upload-img .btn-img img {
  /* 上传封面预览 */
  border-radius: 4px;
  height: 68px;
}

.upload-img .btn-img.preview-img img {
  height: 59px;
}

.upload-img .img-name {
  /* 图片名称 */
  font-size: 12px;
  text-align: center;
  color: #f8b83a;
}
</style>
